import { Component } from "react";

class Settings extends Component {
  componentDidMount() {
    this.props.fetchStrategyStatus(1);
    this.props.fetchStrategyStatus(2);
    this.props.fetchStrategyStatus(3);
    this.props.fetchStrategyStatus(4);
    this.props.fetchStrategyStatus(5);
  }

  render() {
    return (
      <div className="columns">
        <div className="column is-three-fifths-tablet is-offset-one-fifth-tablet">
          <div className="card mt-2 text-center">
            <header className="card-header">
              <h5 className="card-header-title">Strategy #1 Settings</h5>
            </header>

            <section className="card-content">
              <p className={this.props.strategyStatus1 === "running" ? "has-text-success" : "has-text-danger"}>
                {
                  this.props.strategyStatus1 === "running" ? "Running" : "Stopped"
                }
              </p>
            </section>

            <footer className="card-footer">
              <button className="button is-info card-footer-item" onClick={() => this.props.fetchStrategyStatus(1)}>Refresh</button>
              {
                this.props.strategyStatus1 === "running" &&
                <button className="button is-danger card-footer-item" onClick={() => this.props.stopStrategy(1)}>Stop</button>
              }
              {
                this.props.strategyStatus1 === "stopped" &&
                <button className="button is-success card-footer-item" onClick={() => this.props.startStrategy(1)}>Start</button>
              }
            </footer>

            <hr />

            <header className="card-header">
              <h5 className="card-header-title">Strategy #2 Settings</h5>
            </header>

            <section className="card-content">
              <p className={this.props.strategyStatus2 === "running" ? "has-text-success" : "has-text-danger"}>
                {
                  this.props.strategyStatus2 === "running" ? "Running" : "Stopped"
                }
              </p>
            </section>

            <footer className="card-footer">
              <button className="button is-info card-footer-item" onClick={() => this.props.fetchStrategyStatus(2)}>Refresh</button>
              {
                this.props.strategyStatus2 === "running" &&
                <button className="button is-danger card-footer-item" onClick={() => this.props.stopStrategy(2)}>Stop</button>
              }
              {
                this.props.strategyStatus2 === "stopped" &&
                <button className="button is-success card-footer-item" onClick={() => this.props.startStrategy(2)}>Start</button>
              }
            </footer>

            <hr />

            <header className="card-header">
              <h5 className="card-header-title">Strategy #3 Settings</h5>
            </header>

            <section className="card-content">
              <p className={this.props.strategyStatus3 === "running" ? "has-text-success" : "has-text-danger"}>
                {
                  this.props.strategyStatus3 === "running" ? "Running" : "Stopped"
                }
              </p>
            </section>

            <footer className="card-footer">
              <button className="button is-info card-footer-item" onClick={() => this.props.fetchStrategyStatus(3)}>Refresh</button>
              {
                this.props.strategyStatus3 === "running" &&
                <button className="button is-danger card-footer-item" onClick={() => this.props.stopStrategy(3)}>Stop</button>
              }
              {
                this.props.strategyStatus3 === "stopped" &&
                <button className="button is-success card-footer-item" onClick={() => this.props.startStrategy(3)}>Start</button>
              }
            </footer>

            <hr />

            <header className="card-header">
              <h5 className="card-header-title">Strategy #4 Settings</h5>
            </header>

            <section className="card-content">
              <p className={this.props.strategyStatus4 === "running" ? "has-text-success" : "has-text-danger"}>
                {
                  this.props.strategyStatus4 === "running" ? "Running" : "Stopped"
                }
              </p>
            </section>

            <footer className="card-footer">
              <button className="button is-info card-footer-item" onClick={() => this.props.fetchStrategyStatus(4)}>Refresh</button>
              {
                this.props.strategyStatus4 === "running" &&
                <button className="button is-danger card-footer-item" onClick={() => this.props.stopStrategy(4)}>Stop</button>
              }
              {
                this.props.strategyStatus4 === "stopped" &&
                <button className="button is-success card-footer-item" onClick={() => this.props.startStrategy(4)}>Start</button>
              }
            </footer>

            <hr />

            <header className="card-header">
              <h5 className="card-header-title">Strategy #5 Settings</h5>
            </header>

            <section className="card-content">
              <p className={this.props.strategyStatus5 === "running" ? "has-text-success" : "has-text-danger"}>
                {
                  this.props.strategyStatus5 === "running" ? "Running" : "Stopped"
                }
              </p>
            </section>

            <footer className="card-footer">
              <button className="button is-info card-footer-item" onClick={() => this.props.fetchStrategyStatus(5)}>Refresh</button>
              {
                this.props.strategyStatus5 === "running" &&
                <button className="button is-danger card-footer-item" onClick={() => this.props.stopStrategy(5)}>Stop</button>
              }
              {
                this.props.strategyStatus5 === "stopped" &&
                <button className="button is-success card-footer-item" onClick={() => this.props.startStrategy(5)}>Start</button>
              }
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;