import { Component } from "react";

class Strategy5AddDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      brokerName: "",
      datafeedName: "",

      startTime: "09:30",
      stopTime: "15:20",
      maxNumStocks: 0,
      expiry: "2023-01-01",
      lots: 0,
      strikeDistance: 0,
      trend: "bullish",
      timeframe: "minute",
      tradingMode: "Paper"
    }
  }

  componentDidMount() {
    let localStorageState = localStorage.getItem("alpha-agent-inputs-5");

    if (localStorageState === undefined || localStorageState === null || localStorageState === "") {
      let stateToSave = JSON.stringify(this.state);
      localStorage.setItem("alpha-agent-inputs-5", stateToSave);

    } else {
      localStorageState = JSON.parse(localStorageState);
      this.setState(localStorageState);
    }
  }

  componentDidUpdate() {
    console.log(this.state);
  }

  handleEdit = e => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => {
      const newState = { ...prevState };
      newState[name] = value;
      return newState;
    }, () => {
      let stateToSave = JSON.stringify(this.state);
      localStorage.setItem("alpha-agent-inputs-5", stateToSave);
    });
  }

  handleAdd = async () => {
    await this.props.addCondition(this.state, 5);
    this.props.hideAddDialog();
  }

  render() {
    return (
      <div className={this.props.open ? "modal is-active" : "modal"}>
        <div className="modal-background" onClick={this.props.hideAddDialog}></div>

        <div className="modal-card is-fullwidth">
          <header className="modal-card-head">
            <h5 className="modal-card-title">Add Condition</h5>
            <button className="delete" onClick={this.props.hideAddDialog}></button>
          </header>

          <section className="modal-card-body">
            <p className="subtitle">Add a new condition which will run its seperate strategy instance</p>

            <form onSubmit={this.handleAdd}>
              <div className="field mb-3">
                <label htmlFor="broker-name" className="label">Broker Name:</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      id="broker-name"
                      name="brokerName"
                      onChange={this.handleEdit}
                      value={this.state.brokerName}
                    >
                      {
                        this.props.brokers.map(broker => (
                          <option value={broker.name}>{broker.name}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="datafeed-name" className="label">Datafeed Name:</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      id="datafeed-name"
                      name="datafeedName"
                      onChange={this.handleEdit}
                      value={this.state.datafeedName}
                    >
                      {
                        this.props.brokers.map(broker => (
                          <option value={broker.name}>{broker.name}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="start-time" className="label">Start Time:</label>
                <div className="control">
                  <input
                    type="time"
                    id="start-time"
                    name="startTime"
                    className="input"
                    onChange={this.handleEdit}
                    value={this.state.startTime}
                  />
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="stop-time" className="label">Stop Time:</label>
                <div className="control">
                  <input
                    type="time"
                    id="stop-time"
                    name="stopTime"
                    className="input"
                    onChange={this.handleEdit}
                    value={this.state.stopTime}
                  />
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="max-num-stocks" className="label">Max No. of Stocks:</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    name="maxNumStocks"
                    id="max-num-stocks"
                    onChange={this.handleEdit}
                    value={this.state.maxNumStocks}
                  />
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="expiry" className="label">Expiry:</label>
                <div className="control">
                  <input
                    id="expiry"
                    type="date"
                    name="expiry"
                    className="input"
                    value={this.state.expiry}
                    onChange={this.handleEdit}
                  />
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="lots" className="label">Lots:</label>
                <div className="control">
                  <input
                    id="lots"
                    name="lots"
                    type="number"
                    className="input"
                    value={this.state.lots}
                    onChange={this.handleEdit}
                  />
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="strike-distance" className="label">Strike Distance:</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    id="strike-distance"
                    name="strikeDistance"
                    onChange={this.handleEdit}
                    value={this.state.strikeDistance}
                  />
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="trend" className="label">Trend:</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      id="trend"
                      name="trend"
                      value={this.state.trend}
                      onChange={this.handleEdit}
                    >
                      <option value="bullish">bullish</option>
                      <option value="bearish">bearish</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="timeframe" className="label">Timeframe:</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      id="timeframe"
                      name="timeframe"
                      onChange={this.handleEdit}
                      value={this.state.timeframe}
                    >
                      <option value="minute">minute</option>
                      <option value="2minute">2minute</option>
                      <option value="3minute">3minute</option>
                      <option value="5minute">5minute</option>
                      <option value="15minute">15minute</option>
                      <option value="30minute">30minute</option>
                      <option value="60minute">60minute</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="trading-mode" className="label">Trading Mode:</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      id="trading-mode"
                      name="tradingMode"
                      onChange={this.handleEdit}
                      value={this.state.tradingMode}
                    >
                      <option value="Paper">Paper</option>
                      <option value="Live">Live</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </section>

          <footer className="modal-card-foot">
            <button onClick={this.handleAdd} className="button is-primary">Add</button>
            <button onClick={this.props.hideAddDialog} className="button is-danger">Cancel</button>
          </footer>
        </div>
      </div>
    );
  }
}

export default Strategy5AddDialog;