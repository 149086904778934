import { Component } from "react";

class Strategy2AddDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrip: "NSE:NIFTY 50",
      expiry: "2023-01-01",
      expiryType: "Weekly",
      lots1: 0,
      lots2: 0,
      strikeDistance: 0,
      upperLimit: 0,
      lowerLimit: 0
    }
  }

  componentDidMount() {
    let localStorageState = localStorage.getItem("alpha-agent-inputs-2");

    if (localStorageState === undefined || localStorageState === null || localStorageState === "") {
      let stateToSave = JSON.stringify(this.state);
      localStorage.setItem("alpha-agent-inputs-2", stateToSave);

    } else {
      localStorageState = JSON.parse(localStorageState);
      this.setState(localStorageState);
    }
  }

  componentDidUpdate() {
    console.log(this.state);
  }

  handleEdit = e => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => {
      const newState = { ...prevState };
      newState[name] = value;
      return newState;
    }, () => {
      let stateToSave = JSON.stringify(this.state);
      localStorage.setItem("alpha-agent-inputs-2", stateToSave);
    });
  }

  handleAdd = async () => {
    await this.props.addCondition(this.state, 2);
    this.props.hideAddDialog();
  }

  render() {
    return (
      <div className={this.props.open ? "modal is-active" : "modal"}>
        <div className="modal-background" onClick={this.props.hideAddDialog}></div>

        <div className="modal-card is-fullwidth">
          <header className="modal-card-head">
            <h5 className="modal-card-title">Add Condition</h5>
            <button className="delete" onClick={this.props.hideAddDialog}></button>
          </header>

          <section className="modal-card-body">
            <p className="subtitle">Add a new condition which will run its seperate strategy instance</p>

            <form onSubmit={this.handleAdd}>
              <div className="field mb-3">
                <label htmlFor="scrip" className="label">Scrip:</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      id="scrip"
                      name="scrip"
                      value={this.state.scrip}
                      onChange={this.handleEdit}
                    >
                      <option value="NSE:NIFTY 50">NSE:NIFTY 50</option>
                      <option value="NSE:NIFTY BANK">NSE:NIFTY BANK</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="expiry" className="label">Expiry:</label>
                <div className="control">
                  <input
                    id="expiry"
                    type="date"
                    name="expiry"
                    className="input"
                    value={this.state.expiry}
                    onChange={this.handleEdit}
                  />
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="expiry-type" className="label">Expiry Type:</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      id="expiry-type"
                      name="expiryType"
                      onChange={this.handleEdit}
                      value={this.state.expiryType}
                    >
                      <option value="Weekly">Weekly</option>
                      <option value="Monthly">Monthly</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="lots-1" className="label">Lots 1:</label>
                <div className="control">
                  <input
                    id="lots-1"
                    name="lots1"
                    type="number"
                    className="input"
                    value={this.state.lots1}
                    onChange={this.handleEdit}
                  />
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="lots-2" className="label">Lots 2:</label>
                <div className="control">
                  <input
                    id="lots-2"
                    name="lots2"
                    type="number"
                    className="input"
                    value={this.state.lots2}
                    onChange={this.handleEdit}
                  />
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="strike-distance" className="label">Strike Distance:</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    id="strike-distance"
                    name="strikeDistance"
                    onChange={this.handleEdit}
                    value={this.state.strikeDistance}
                  />
                </div>
              </div>

              <hr />

              <div className="field mb-3">
                <label htmlFor="upper-limit" className="label">Upper Limit:</label>
                <div className="control">
                  <input
                    type="number"
                    id="upper-limit"
                    name="upperLimit"
                    className="input"
                    onChange={this.handleEdit}
                    value={this.state.upperLimit}
                  />
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="lower-limit" className="label">lower Limit:</label>
                <div className="control">
                  <input
                    type="number"
                    id="lower-limit"
                    name="lowerLimit"
                    className="input"
                    onChange={this.handleEdit}
                    value={this.state.lowerLimit}
                  />
                </div>
              </div>
            </form>
          </section>

          <footer className="modal-card-foot">
            <button onClick={this.handleAdd} className="button is-primary">Add</button>
            <button onClick={this.props.hideAddDialog} className="button is-danger">Cancel</button>
          </footer>
        </div>
      </div>
    );
  }
}

export default Strategy2AddDialog;
