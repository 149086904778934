import { Component } from "react";

import { GrClose } from "react-icons/gr";
import Strategy3InfoBox from "./strategy3InfoBox.jsx";
import Strategy3AddDialog from "./strategy3AddDialog.jsx";
import { FaInfo, FaTrash, FaPlay, FaPause } from "react-icons/fa";

class Strategy3Conditions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addModalShow: false,
      infoBoxShow: false,
      infoBoxCondition: {},
    }

    this.updateIntervalID = null;
  }

  componentDidMount() {
    var intervalId = setInterval(() => this.props.getConditions(3), 1000);
    this.updateIntervalID = intervalId;
  }

  componentWillUnmount() {
    clearInterval(this.updateIntervalID);
    this.updateIntervalID = null;
  }

  showAddDialog = () => {
    this.setState(prevState => {
      const newState = { ...prevState };
      newState.addModalShow = true;
      return newState;
    });
  }

  hideAddDialog = () => {
    this.setState(prevState => {
      const newState = { ...prevState };
      newState.addModalShow = false;
      return newState;
    });
  }

  showInfoBox = condition => {
    this.setState(prevState => {
      const newState = { ...prevState };
      newState.infoBoxShow = true;
      newState.infoBoxCondition = condition;
      return newState;
    });
  }

  closeInfoBox = () => {
    this.setState(prevState => {
      const newState = { ...prevState };
      newState.infoBoxShow = false;
      newState.infoBoxID = null;
      return newState;
    });
  }

  render() {
    console.log(this.props.conditions);

    return (
      <div>
        <div className="card m-2">
          <header className="card-header has-text-centered">
            <h5 className="card-header-title">Heikin Ashi Strategy (double timeframe)</h5>
          </header>

          <section className="card-content overflow-auto">
            <table className="table is-striped is-hoverable has-text-centered is-fullwidth">
              <thead>
                <tr>
                  <th align="center">ID</th>
                  <th align="center">Broker</th>
                  <th align="center">Scrip</th>
                  <th align="center">CE/PE Strike</th>
                  <th align="center">CE/PE Next Move</th>
                  <th align="center">CE/PE Running P&L</th>
                  <th align="center">CE/PE Booked P&L</th>
                  <th align="center">Start/Stop</th>
                  <th align="center">Squareoff</th>
                  <th align="center">Delete</th>
                  <th align="center">Info</th>
                </tr>
              </thead>
              <tbody>
                {this.props.conditions.map((condition, i) => (
                  <tr key={i}>
                    <th scope="row">{condition.id}</th>
                    <td align="center">{condition.brokerName}</td>
                    <td align="center">{condition.scrip}</td>
                    <td align="center">{condition.ceEnteredIns}, {condition.peEnteredIns}</td>
                    <td align="center">{condition.ceNextMove}, {condition.peNextMove}</td>
                    <td align="center">{condition.ceRunningPnl}, {condition.peRunningPnl}</td>
                    <td align="center">{condition.ceBookedPnl}, {condition.peBookedPnl}</td>
                    <td>
                      {
                        condition.stopped ?
                        <button
                          className="button is-danger"
                          onClick={() => this.props.startCondition(condition.id, 3)}
                        >
                          <FaPlay />
                        </button> :
                        <button
                          className="button is-success"
                          onClick={() => this.props.stopCondition(condition.id, 3)}
                        >
                          <FaPause />
                        </button>
                      }
                    </td>
                    <td align="center">
                      <button
                        className="button is-warning"
                        onClick={() => this.props.handleClosePositions(condition.id, 3)}
                      >
                        <GrClose />
                      </button>
                    </td>
                    <td align="center">
                      <button
                        className="button is-danger"
                        onClick={() => this.props.handleConditionDelete(condition.id, 3)}
                      >
                        <FaTrash />
                      </button>
                    </td>
                    <td align="center">
                      <button className="button is-info" onClick={() => this.showInfoBox(condition)}>
                        <FaInfo />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>

          <footer className="card-footer">
            <button className="card-footer-item button is-link" onClick={() => this.props.getConditions(3)}>Refresh</button>
            <button className="card-footer-item button is-primary" onClick={this.showAddDialog}>Add Condition</button>
          </footer>
        </div>

        <Strategy3AddDialog
          brokers={this.props.brokers}
          open={this.state.addModalShow}
          hideAddDialog={this.hideAddDialog}
          addCondition={this.props.addCondition}
        />
        <Strategy3InfoBox
          show={this.state.infoBoxShow}
          handleClose={this.closeInfoBox}
          condition={this.state.infoBoxCondition}
        />
      </div>
    );
  }
}

export default Strategy3Conditions;