import { Component } from "react";

class Brokers extends Component {
  componentDidMount() {
    this.props.getBrokers();
  }

  render() {
    return (
      <div>
        <div className="card m-2">
          <header className="card-header has-text-centered">
            <h5 className="card-header-title">Brokers</h5>
          </header>

          <section className="card-content overflow-auto">
            <table className="table is-striped is-hoverable has-text-centered is-fullwidth">
              <thead>
                <tr>
                  <th align="center">ID</th>
                  <th align="center">Name</th>
                  <th align="center">Type</th>
                  <th align="center">Key 1</th>
                  <th align="center">Key 2</th>
                  <th align="center">Logged in</th>
                  <th align="center">Login</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.props.brokers.map((broker, i) => (
                    <tr key={i}>
                      <th scope="row">{broker.id}</th>
                      <td align="center">{broker.name}</td>
                      <td align="center">{broker.type}</td>
                      <td align="center">{broker.key1}</td>
                      <td align="center">{broker.key2}</td>
                      <td align="center">{broker.loggedIn ? "Yes" : "No"}</td>
                      <td align="center">
                        <button className="button is-primary" onClick={() => this.props.handleBrokerLogin(broker.id)}>
                          Login
                        </button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </section>
        </div>
      </div>
    );
  }
}

export default Brokers;