import { Component } from "react";

import Logs from "./logs.jsx";
import Navbar from "./navbar.jsx";
import Brokers from "./brokers.jsx";
import Settings from "./settings.jsx";
import Orderbook from "./orderbook.jsx";
import BrokerLoginWindow from "./brokerLoginWindow.jsx";
import Strategy1Conditions from "./strategy1Conditions.jsx";
import Strategy2Conditions from "./strategy2Conditions.jsx";
import Strategy3Conditions from "./strategy3Conditions.jsx";
import Strategy4Conditions from "./strategy4Conditions.jsx";
import Strategy5Conditions from "./strategy5Conditions.jsx";

class MainPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: "strategy1Conditions"
    }

    this.updateIntervalID = null;
  }

  componentDidMount() {
    var intervalId = setInterval(() => this.props.getBrokers(), 2500);
    this.updateIntervalID = intervalId;
  }

  componentWillUnmount() {
    clearInterval(this.updateIntervalID);
    this.updateIntervalID = null;
  }

  changePage = page => {
    console.log(`Changing to ${page}`);

    this.setState({
      currentPage: page
    });
  }

  render() {
    let page;

    if (this.state.currentPage === "strategy1Conditions") {
      page = <Strategy1Conditions
        getConditions={this.props.getConditions}
        conditions={this.props.strategy1Conditions}
        saveParams={this.props.saveParams}
        handleParamsEdit={this.props.handleParamsEdit}
        addCondition={this.props.addCondition}
        handleConditionDelete={this.props.handleConditionDelete}
        handleClosePositions={this.props.handleClosePositions}
        startCondition={this.props.startCondition}
        stopCondition={this.props.stopCondition}
      />
    } else if (this.state.currentPage === "strategy2Conditions") {
      page = <Strategy2Conditions
        getConditions={this.props.getConditions}
        conditions={this.props.strategy2Conditions}
        saveParams={this.props.saveParams}
        handleParamsEdit={this.props.handleParamsEdit}
        addCondition={this.props.addCondition}
        handleConditionDelete={this.props.handleConditionDelete}
        handleClosePositions={this.props.handleClosePositions}
        startCondition={this.props.startCondition}
        stopCondition={this.props.stopCondition}
      />
    } else if (this.state.currentPage === "strategy3Conditions") {
      page = <Strategy3Conditions
        brokers={this.props.brokers}
        getConditions={this.props.getConditions}
        conditions={this.props.strategy3Conditions}
        saveParams={this.props.saveParams}
        handleParamsEdit={this.props.handleParamsEdit}
        addCondition={this.props.addCondition}
        handleConditionDelete={this.props.handleConditionDelete}
        handleClosePositions={this.props.handleClosePositions}
        startCondition={this.props.startCondition}
        stopCondition={this.props.stopCondition}
      />
    } else if (this.state.currentPage === "strategy4Conditions") {
      page = <Strategy4Conditions
        brokers={this.props.brokers}
        getConditions={this.props.getConditions}
        conditions={this.props.strategy4Conditions}
        saveParams={this.props.saveParams}
        handleParamsEdit={this.props.handleParamsEdit}
        addCondition={this.props.addCondition}
        handleConditionDelete={this.props.handleConditionDelete}
        handleClosePositions={this.props.handleClosePositions}
        startCondition={this.props.startCondition}
        stopCondition={this.props.stopCondition}
      />
    } else if (this.state.currentPage === "strategy5Conditions") {
      page = <Strategy5Conditions
        brokers={this.props.brokers}
        getConditions={this.props.getConditions}
        conditions={this.props.strategy5Conditions}
        saveParams={this.props.saveParams}
        handleParamsEdit={this.props.handleParamsEdit}
        addCondition={this.props.addCondition}
        handleConditionDelete={this.props.handleConditionDelete}
        handleClosePositions={this.props.handleClosePositions}
        startCondition={this.props.startCondition}
        stopCondition={this.props.stopCondition}
      />
    } else if (this.state.currentPage === "brokers") {
      page = <Brokers
        brokers={this.props.brokers}
        getBrokers={this.props.getBrokers}
        handleBrokerLogin={this.props.handleBrokerLogin}
      />
    } else if (this.state.currentPage === "orderbook") {
      page = <Orderbook
        orders={this.props.orders}
        getOrders={this.props.getOrders}
        clearOrders={this.props.clearOrders}
      />
    } else if (this.state.currentPage === "logs") {
      page = <Logs
        logs={this.props.logs}
        getLogs={this.props.getLogs}
        clearLogs={this.props.clearLogs}
      />
    } else if (this.state.currentPage === "settings") {
      page = <Settings
        stopStrategy={this.props.stopStrategy}
        startStrategy={this.props.startStrategy}
        strategyStatus1={this.props.strategyStatus1}
        strategyStatus2={this.props.strategyStatus2}
        strategyStatus3={this.props.strategyStatus3}
        strategyStatus4={this.props.strategyStatus4}
        strategyStatus5={this.props.strategyStatus5}
        fetchStrategyStatus={this.props.fetchStrategyStatus}
      />
    }

    return (
      <div>
        <Navbar
          username={this.props.username}
          handleLogout={this.props.handleLogout}
        />

        <div className="tabs is-toggle is-toggle-rounded is-fullwidth p-2 m-2">
          <ul>
            <li
              className={this.state.currentPage === "strategy1Conditions" ? "is-active" : ""}
            >
              <a
                href="/#"
                onClick={() => this.changePage("strategy1Conditions")}
                className={this.state.currentPage !== "strategy1Conditions" ? "has-background-white" : ""}
              >
                Strategy #1
              </a>
            </li>

            <li
              className={this.state.currentPage === "strategy2Conditions" ? "is-active" : ""}
            >
              <a
                href="/#"
                onClick={() => this.changePage("strategy2Conditions")}
                className={this.state.currentPage !== "strategy2Conditions" ? "has-background-white" : ""}
              >
                Strategy #2
              </a>
            </li>

            <li
              className={this.state.currentPage === "strategy3Conditions" ? "is-active" : ""}
            >
              <a
                href="/#"
                onClick={() => this.changePage("strategy3Conditions")}
                className={this.state.currentPage !== "strategy3Conditions" ? "has-background-white" : ""}
              >
                Strategy #3
              </a>
            </li>

            <li
              className={this.state.currentPage === "strategy4Conditions" ? "is-active" : ""}
            >
              <a
                href="/#"
                onClick={() => this.changePage("strategy4Conditions")}
                className={this.state.currentPage !== "strategy4Conditions" ? "has-background-white" : ""}
              >
                Strategy #4
              </a>
            </li>

            <li
              className={this.state.currentPage === "strategy5Conditions" ? "is-active" : ""}
            >
              <a
                href="/#"
                onClick={() => this.changePage("strategy5Conditions")}
                className={this.state.currentPage !== "strategy5Conditions" ? "has-background-white" : ""}
              >
                Strategy #5
              </a>
            </li>

            <li
              className={this.state.currentPage === "brokers" ? "is-active" : ""}
            >
              <a
                href="/#"
                onClick={() => this.changePage("brokers")}
                className={this.state.currentPage !== "brokers" ? "has-background-white" : ""}
              >
                Brokers
              </a>
            </li>

            <li
              className={this.state.currentPage === "orderbook" ? "is-active" : ""}
            >
              <a
                href="/#"
                onClick={() => this.changePage("orderbook")}
                className={this.state.currentPage !== "orderbook" ? "has-background-white" : ""}
              >
                Orderbook
              </a>
            </li>

            <li
              className={this.state.currentPage === "logs" ? "is-active" : ""}
            >
              <a
                href="/#"
                onClick={() => this.changePage("logs")}
                className={this.state.currentPage !== "logs" ? "has-background-white" : ""}
              >
                Logs
              </a>
            </li>

            <li
              className={this.state.currentPage === "settings" ? "is-active" : ""}
            >
              <a
                href="/#"
                onClick={() => this.changePage("settings")}
                className={this.state.currentPage !== "settings" ? "has-background-white" : ""}
              >
                Settings
              </a>
            </li>
          </ul>
        </div>

        <BrokerLoginWindow
          showBrokerLoginWindow={this.props.showBrokerLoginWindow}
          generateShoonyaAccessToken={this.props.generateShoonyaAccessToken}
        />

        {page}
      </div>
    );
  }
}

export default MainPage;