import { Component } from "react";

class Strategy5InfoBox extends Component {
  render() {
    return (
      <div className={this.props.show ? "modal is-active" : "modal"}>
        <div className="modal-background" onClick={this.props.handleClose}></div>

        <div className="modal-card wide-modal">
          <header className="modal-card-head">
            <h5 className="modal-card-title">Condition Info (Strategy #5)</h5>
            <button className="delete" onClick={this.props.handleClose}></button>
          </header>

          <section className="modal-card-body">
            <p className="subtitle">Statistics info of condition - #{this.props.condition.id}</p>

            <h4 className="is-size-4 mb-3">Inputs:</h4>

            <table className="table is-striped is-hoverable is-fullwidth is-bordered">
              <tbody>
                <tr>
                  <th>Broker:</th>
                  <td>{this.props.condition.brokerName}</td>
                </tr>
                <tr>
                  <th>Datafeed:</th>
                  <td>{this.props.condition.datafeedName}</td>
                </tr>
                <tr>
                  <th>Start Time:</th>
                  <td>{this.props.condition.startTime}</td>
                </tr>
                <tr>
                  <th>Stop Time:</th>
                  <td>{this.props.condition.stopTime}</td>
                </tr>
                <tr>
                  <th>Max No. of Stocks:</th>
                  <td>{this.props.condition.maxNumStocks}</td>
                </tr>
                <tr>
                  <th>Expiry:</th>
                  <td>{this.props.condition.expiry}</td>
                </tr>
                <tr>
                  <th>Lots:</th>
                  <td>{this.props.condition.lots}</td>
                </tr>
                <tr>
                  <th>Strike Distance:</th>
                  <td>{this.props.condition.strikeDistance}</td>
                </tr>
                <tr>
                  <th>Trend:</th>
                  <td>{this.props.condition.trend}</td>
                </tr>
                <tr>
                  <th>Timeframe:</th>
                  <td>{this.props.condition.timeframe}</td>
                </tr>
                <tr>
                  <th>Trading Mode:</th>
                  <td>{this.props.condition.tradingMode}</td>
                </tr>
              </tbody>
            </table>

            <hr />

            <h4 className="is-size-4 mb-2">Statistics:</h4>

            <table className="table is-striped is-hoverable is-fullwidth is-bordered">
              <tbody>
                <tr>
                  <th>Stopped:</th>
                  <td>{this.props.condition.stopped ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <th>Number of Trades:</th>
                  <td>{this.props.condition.numberOfTrades}</td>
                </tr>
              </tbody>
            </table>

            <div className="overflow-auto">
              <table className="table is-striped is-hoverable is-fullwidth is-bordered">
                <thead>
                  <tr>
                    <th>Stock</th>
                    <th>Stock Type</th>
                    <th>Status</th>
                    <th>Entered Ins</th>
                    <th>Entered Qty</th>
                    <th>Entered Price</th>
                    <th>Locked SL</th>
                    <th>P&L at Trail</th>
                    <th>LTP</th>
                    <th>Running P&L</th>
                    <th>Booked P&L</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.props.condition.stocks.map((stock, i) => (
                      <tr>
                        <td>{stock.stock}</td>
                        <td>{stock.stockType}</td>
                        <td>{stock.status}</td>
                        <td>{stock.enteredIns}</td>
                        <td>{stock.enteredQty}</td>
                        <td>{stock.enteredPrice}</td>
                        <td>{stock.lockedSL}</td>
                        <td>{stock.pnlAtTrail} %</td>
                        <td>{stock.ltp}</td>
                        <td>{stock.runningPnl}</td>
                        <td>{stock.bookedPnl}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Strategy5InfoBox;