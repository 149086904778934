import { Component } from "react";

class Strategy2InfoBox extends Component {
  render() {
    return (
      <div className={this.props.show ? "modal is-active" : "modal"}>
        <div className="modal-background" onClick={this.props.handleClose}></div>

        <div className="modal-card">
          <header className="modal-card-head">
            <h5 className="modal-card-title">Condition Info (Strategy #2)</h5>
            <button className="delete" onClick={this.props.handleClose}></button>
          </header>

          <section className="modal-card-body">
            <p className="subtitle">Statistics info of condition - #{this.props.condition.id}</p>

            <h4 className="is-size-4 mb-3">Inputs:</h4>

            <table className="table is-striped is-hoverable is-fullwidth is-bordered">
              <tbody>
                <tr>
                  <th>Scrip:</th>
                  <td>{this.props.condition.scrip}</td>
                </tr>
                <tr>
                  <th>Expiry:</th>
                  <td>{this.props.condition.expiry}</td>
                </tr>
                <tr>
                  <th>Expiry Type:</th>
                  <td>{this.props.condition.expiryType}</td>
                </tr>
                <tr>
                  <th>Lots 1:</th>
                  <td>{this.props.condition.lots1}</td>
                </tr>
                <tr>
                  <th>Lots 2:</th>
                  <td>{this.props.condition.lots2}</td>
                </tr>
                <tr>
                  <th>Strike Distance:</th>
                  <td>{this.props.condition.strikeDistance}</td>
                </tr>
                <tr>
                  <th>Upper Limit:</th>
                  <td>{this.props.condition.upperLimit}</td>
                </tr>
                <tr>
                  <th>Lower Limit:</th>
                  <td>{this.props.condition.lowerLimit}</td>
                </tr>
              </tbody>
            </table>

            <hr />

            <h4 className="is-size-4 mb-2">Statistics:</h4>

            <table className="table is-striped is-hoverable is-fullwidth is-bordered">
              <tbody>
                <tr>
                  <th>Stopped:</th>
                  <td>{this.props.condition.stopped ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <th>Next Move:</th>
                  <td>{this.props.condition.nextMove}</td>
                </tr>
                <tr>
                  <th>CE Entered Price:</th>
                  <td>{this.props.condition.ceEnteredPrice}</td>
                </tr>
                <tr>
                  <th>PE Entered Price:</th>
                  <td>{this.props.condition.peEnteredPrice}</td>
                </tr>
                <tr>
                  <th>CE Entered Qty:</th>
                  <td>{this.props.condition.ceEnteredQty}</td>
                </tr>
                <tr>
                  <th>PE Entered Qty:</th>
                  <td>{this.props.condition.peEnteredQty}</td>
                </tr>
                <tr>
                  <th>CE Ins:</th>
                  <td>{this.props.condition.ceIns}</td>
                </tr>
                <tr>
                  <th>PE Ins:</th>
                  <td>{this.props.condition.peIns}</td>
                </tr>
                <tr>
                  <th>CE Strike:</th>
                  <td>{this.props.condition.ceStrike}</td>
                </tr>
                <tr>
                  <th>PE Strike:</th>
                  <td>{this.props.condition.peStrike}</td>
                </tr>
                <tr>
                  <th>CE LTP:</th>
                  <td>{this.props.condition.ceLtp}</td>
                </tr>
                <tr>
                  <th>PE LTP:</th>
                  <td>{this.props.condition.peLtp}</td>
                </tr>
                <tr>
                  <th>CE Running P&L:</th>
                  <td>{this.props.condition.ceRunningPnl}</td>
                </tr>
                <tr>
                  <th>PE Running P&L:</th>
                  <td>{this.props.condition.peRunningPnl}</td>
                </tr>
                <tr>
                  <th>Running P&L:</th>
                  <td>{this.props.condition.runningPnl}</td>
                </tr>
                <tr>
                  <th>Booked P&L:</th>
                  <td>{this.props.condition.bookedPnl}</td>
                </tr>
              </tbody>
            </table>
          </section>

          <footer className="modal-card-foot"></footer>
        </div>
      </div>
    );
  }
}

export default Strategy2InfoBox;
