import { Component } from "react";

class Strategy1AddDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrip: "NSE:NIFTY 50",
      expiry: "2023-01-01",
      expiryType: "Weekly",
      ceLots: 0,
      peLots: 0,
      trade: "BUY",
      sl: 0,
      strikeDistance: 0,
      markedLimitShiftDiff: 0,
      tradingMode: "Paper",
      totalTP: 0
    }
  }

  componentDidMount() {
    let localStorageState = localStorage.getItem("alpha-agent-inputs-1");

    if (localStorageState === undefined || localStorageState === null || localStorageState === "") {
      let stateToSave = JSON.stringify(this.state);
      localStorage.setItem("alpha-agent-inputs-1", stateToSave);

    } else {
      localStorageState = JSON.parse(localStorageState);
      this.setState(localStorageState);
    }
  }

  componentDidUpdate() {
    console.log(this.state);
  }

  handleEdit = e => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => {
      const newState = { ...prevState };
      newState[name] = value;
      return newState;
    }, () => {
      let stateToSave = JSON.stringify(this.state);
      localStorage.setItem("alpha-agent-inputs-1", stateToSave);
    });
  }

  handleAdd = async () => {
    await this.props.addCondition(this.state, 1);
    this.props.hideAddDialog();
  }

  render() {
    return (
      <div className={this.props.open ? "modal is-active" : "modal"}>
        <div className="modal-background" onClick={this.props.hideAddDialog}></div>

        <div className="modal-card is-fullwidth">
          <header className="modal-card-head">
            <h5 className="modal-card-title">Add Condition</h5>
            <button className="delete" onClick={this.props.hideAddDialog}></button>
          </header>

          <section className="modal-card-body">
            <p className="subtitle">Add a new condition which will run its seperate strategy instance</p>

            <form onSubmit={this.handleAdd}>
              <div className="field mb-3">
                <label htmlFor="scrip" className="label">Scrip:</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      id="scrip"
                      name="scrip"
                      value={this.state.scrip}
                      onChange={this.handleEdit}
                    >
                      <option value="NSE:NIFTY 50">NSE:NIFTY 50</option>
                      <option value="NSE:NIFTY BANK">NSE:NIFTY BANK</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="expiry" className="label">Expiry:</label>
                <div className="control">
                  <input
                    id="expiry"
                    type="date"
                    name="expiry"
                    className="input"
                    value={this.state.expiry}
                    onChange={this.handleEdit}
                  />
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="expiry-type" className="label">Expiry Type:</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      id="expiry-type"
                      name="expiryType"
                      onChange={this.handleEdit}
                      value={this.state.expiryType}
                    >
                      <option value="Weekly">Weekly</option>
                      <option value="Monthly">Monthly</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="ce-lots" className="label">CE Lots:</label>
                <div className="control">
                  <input
                    id="ce-lots"
                    type="number"
                    name="ceLots"
                    className="input"
                    value={this.state.ceLots}
                    onChange={this.handleEdit}
                  />
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="pe-lots" className="label">PE Lots:</label>
                <div className="control">
                  <input
                    id="pe-lots"
                    type="number"
                    name="peLots"
                    className="input"
                    value={this.state.peLots}
                    onChange={this.handleEdit}
                  />
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="trade" className="label">Trade:</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      id="trade"
                      name="trade"
                      value={this.state.trade}
                      onChange={this.handleEdit}
                    >
                      <option value="BUY">BUY</option>
                      <option value="SELL">SELL</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="sl" className="label">SL:</label>
                <div className="control">
                  <input
                    id="sl"
                    name="sl"
                    type="number"
                    className="input"
                    value={this.state.sl}
                    onChange={this.handleEdit}
                  />
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="strike-distance" className="label">Strike Distance:</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    id="strike-distance"
                    name="strikeDistance"
                    onChange={this.handleEdit}
                    value={this.state.strikeDistance}
                  />
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="marked-limit-shift-diff" className="label">Marked Limit Shift Diff:</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    onChange={this.handleEdit}
                    name="markedLimitShiftDiff"
                    id="marked-limit-shift-diff"
                    value={this.state.markedLimitShiftDiff}
                  />
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="trading-mode" className="label">Trading Mode:</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      id="trading-mode"
                      name="tradingMode"
                      onChange={this.handleEdit}
                      value={this.state.tradingMode}
                    >
                      <option value="Paper">Paper</option>
                      <option value="Live">Live</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field mb-3">
                <label htmlFor="total-tp" className="label">Total TP:</label>
                <div className="control">
                  <input
                    id="total-tp"
                    type="number"
                    name="totalTP"
                    className="input"
                    value={this.state.totalTP}
                    onChange={this.handleEdit}
                  />
                </div>
              </div>
            </form>
          </section>

          <footer className="modal-card-foot">
            <button onClick={this.handleAdd} className="button is-primary">Add</button>
            <button onClick={this.props.hideAddDialog} className="button is-danger">Cancel</button>
          </footer>
        </div>
      </div>
    );
  }
}

export default Strategy1AddDialog;
