import "./App.css";
import Cookies from "js-cookie";
import { Component } from "react";
import queryString from "query-string";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

// Components
import MainPage from "./components/mainPage.jsx";
import LoginForm from "./components/loginForm.jsx";

// const qs = require("query-string");

const options = {
  position: "bottom-right",
  pauseOnHover: true,
  closeOnClick: true,
  theme: "colored",
  autoClose: 1500
}

class App extends Component {
  constructor(props) {
    super(props);

    console.log(process.env.NODE_ENV);
    this.URL = process.env.NODE_ENV === "production" ? "" : "http://localhost:8000";

    console.log(this.URL);

    this.state = {
      loggedIn: localStorage.getItem("jwt-token") ? true : false,
      username: "",
      strategy1Conditions: [],
      strategy2Conditions: [],
      strategy3Conditions: [],
      strategy4Conditions: [],
      strategy5Conditions: [],
      orders: [],
      logs: [],
      strategyStatus1: "",
      strategyStatus2: "",
      strategyStatus3: "",
      strategyStatus4: "",
      strategyStatus5: "",
      brokers: [],
      showBrokerLoginWindow: false
    }
  }

  request = async (route, method, body, logoutOnElse=true, msgOnSuccess=true) => {
    let res;

    if (method === "POST") {
      res = await fetch(`${this.URL}/api/${route}`, {
        method: method,
        headers: {
          "Authorization": `jwt-token ${localStorage.getItem("jwt-token")}`,
          "X-CSRFToken": this.getCsrfCookie()
        },
        body: JSON.stringify(body)
      });
    } else {
      res = await fetch(`${this.URL}/api/${route}`, {
        method: method,
        headers: {
          "Authorization": `jwt-token ${localStorage.getItem("jwt-token")}`,
          "X-CSRFToken": this.getCsrfCookie()
        }
      });
    }

    const json = await res.json();
    console.log(json);

    if (json.type === "success") {
      if (msgOnSuccess) {
        toast.success(`Success: ${json.message}`, options);
      }

      return json

    } else if (json.type === "info") {
      if (msgOnSuccess) {
        toast.info(`Success: ${json.message}`, options);
      }

      return json

    } else if (json.type === "error") {
      toast.error(`Error: ${json.message}`, options);

      return json

    } else if (json.type === "generateToken") {
      console.log(`Redirecting to ${json.url}`);
      window.location = json.url;

      return json

    } else if (["takeTOTP"].includes(json.type)) {
      return json

    } else {
      toast.error(`${json.type}: ${json.message}`, options);
      if (logoutOnElse) {
        toast.error("Logged out !", options);
        this.handleLogout();
      }

      return json
    }
  }

  componentDidMount = async () => {
    let parsed = queryString.parse(window.location.search);
    let reqToken = parsed.request_token;

    console.log(reqToken);

    if (reqToken) {
      await this.request("set-access-token/", "POST", {requestToken: reqToken});
      window.location.search = "";
    }

    if (this.state.loggedIn) {
      const json = await this.request("current-user/", "GET");

      if (json.type === "success") {
        this.setState({
          username: json.username
        });
        // this.fetchZerodhaAccessToken();
        // this.fetchStrategyStatus(1);
        // this.fetchStrategyStatus(2);
        // this.fetchStrategyStatus(3);
        // this.fetchStrategyStatus(4);
        // this.fetchStrategyStatus(5);
      }
    }
  }

  getCsrfCookie = () => {
    return Cookies.get("csrftoken");
  }

  handleLogin = async data => {
    console.log(data);
    const json = await this.request("login/", "POST", data, false);

    if (json.type === "success") {
      localStorage.setItem("jwt-token", json.jwtToken);

      this.setState({
        loggedIn: true,
        username: json.username
      });
      // }, () => {
      //   this.fetchStrategyStatus(1);
      //   this.fetchStrategyStatus(2);
      //   this.fetchStrategyStatus(3);
      //   this.fetchStrategyStatus(4);
      //   this.fetchStrategyStatus(5);
      // });
    }
  }

  handleLogout = () => {
    localStorage.removeItem("jwt-token");
    this.setState({loggedIn: false});
  }

  // fetchZerodhaAccessToken = async () => {
  //   let parsed = queryString.parse(window.location.search);
  //   let reqToken = parsed.request_token;

  //   console.log(reqToken);

  //   if (reqToken) {
  //     await this.request("set-access-token/", "POST", {requestToken: reqToken});
  //     window.location.search = "";
  //   }

  //   if (this.state.loggedIn) {
  //     const json = await this.request("zerodha-token/", "GET");

  //     if (json.type === "success") {
  //       this.setState({
  //         username: json.username
  //       }, () => {
  //         this.fetchStrategyStatus(1);
  //         this.fetchStrategyStatus(2);
  //         this.fetchStrategyStatus(3);
  //         this.fetchStrategyStatus(4);
  //         this.fetchStrategyStatus(5);
  //       });
  //     }
  //   }
  // }

  getConditions = async num => {
    const json = await this.request(`conditions/${num}/`, "GET", null, true, false);

    if (json.type === "success") {
      const conditions = json.data;

      if (num === 1) {
        this.setState({strategy1Conditions: conditions});
      } else if (num === 2) {
        this.setState({strategy2Conditions: conditions});
      } else if (num === 3) {
        this.setState({strategy3Conditions: conditions});
      } else if (num === 4) {
        this.setState({strategy4Conditions: conditions});
      } else if (num === 5) {
        this.setState({strategy5Conditions: conditions});
      }
    }
  }

  addCondition = async (state, num) => {
    await this.request(`conditions/add/${num}/`, "POST", {data: state});
    this.getConditions(num);
  }

  handleConditionDelete = async (id, num) => {
    const json = await this.request(`conditions/delete/${id}/${num}/`, "DELETE");

    if (json.type === "success") {
      await this.getConditions(num);
    }
  }

  handleClosePositions = async (id, num) => {
    const json = await this.request(`conditions/close/${id}/${num}/`, "DELETE");

    if (json.type === "success") {
      await this.getConditions(num);
    }
  }

  startCondition = async (id, num) => {
    const json = await this.request(`conditions/start/${id}/${num}/`, "PUT");

    if (json.type === "success") {
      await this.getConditions(num);
    }
  }

  stopCondition = async (id, num) => {
    const json = await this.request(`conditions/stop/${id}/${num}/`, "PUT");

    if (json.type === "success") {
      await this.getConditions(num);
    }
  }

  fetchStrategyStatus = async num => {
    const json = await this.request(`strategy/status/${num}/`, "GET");

    if (json.type === "success") {
      if (json.data === 0) {
        this.setState(prevState => {
          const newState = { ...prevState };
          newState[`strategyStatus${num}`] = "running";
          return newState;
        });

      } else {
        this.setState(prevState => {
          const newState = { ...prevState };
          newState[`strategyStatus${num}`] = "stopped";
          return newState;
        });
      }
    }
  }

  startStrategy = async num => {
    const json = await this.request(`strategy/start/${num}/`, "PUT");

    if (json.type === "success") {
      this.fetchStrategyStatus(num);
    }
  }

  stopStrategy = async num => {
    const json = await this.request(`strategy/stop/${num}/`, "PUT");

    if (json.type === "success") {
      this.fetchStrategyStatus(num);
    }
  }

  getBrokers = async () => {
    const json = await this.request("brokers/", "GET", null, true, false);

    this.setState(prevState => {
      const newState = { ...prevState };
      newState.brokers = json.brokers;
      return newState;
    });
  }

  handleBrokerLogin = async id => {
    const json = await this.request(`broker-token/${id}/`, "GET");

    if (json.type === "takeTOTP") {
      this.setState({
        showBrokerLoginWindow: true
      });
    }
  }

  generateShoonyaAccessToken = async totp => {
    const json = await this.request("set-access-token/", "POST", {requestToken: totp});

    if (json.type === "success") {
      this.setState({
        showBrokerLoginWindow: false
      });
    }
  }

  getOrders = async () => {
    const json = await this.request("orders/", "GET");

    this.setState(prevState => {
      const newState = { ...prevState };
      newState.orders = json.orders;
      return newState;
    });

    console.log(json.orders);
  }

  clearOrders = async () => {
    await this.request("orders/clear/", "DELETE");
    this.getOrders();
  }

  getLogs = async () => {
    const json = await this.request("logs/", "GET");

    this.setState(prevState => {
      const newState = { ...prevState };
      newState.logs = json.logs;
      return newState;
    });

    console.log(json.logs);
  }

  clearLogs = async () => {
    await this.request("logs/clear/", "DELETE");
    this.getLogs();
  }

  render() {
    let page = null;

    if (!this.state.loggedIn) {
      page = <LoginForm
        handleLogin={this.handleLogin}
      />

    } else {
      page = <MainPage
        URL={this.URL}
        username={this.state.username}
        handleLogout={this.handleLogout}

        strategy1Conditions={this.state.strategy1Conditions}
        strategy2Conditions={this.state.strategy2Conditions}
        strategy3Conditions={this.state.strategy3Conditions}
        strategy4Conditions={this.state.strategy4Conditions}
        strategy5Conditions={this.state.strategy5Conditions}
        getConditions={this.getConditions}
        addCondition={this.addCondition}
        handleParamsEdit={this.handleParamsEdit}
        saveParams={this.saveParams}
        handleConditionDelete={this.handleConditionDelete}
        handleClosePositions={this.handleClosePositions}
        startCondition={this.startCondition}
        stopCondition={this.stopCondition}

        brokers={this.state.brokers}
        getBrokers={this.getBrokers}
        handleBrokerLogin={this.handleBrokerLogin}
        showBrokerLoginWindow={this.state.showBrokerLoginWindow}
        generateShoonyaAccessToken={this.generateShoonyaAccessToken}

        orders={this.state.orders}
        getOrders={this.getOrders}
        clearOrders={this.clearOrders}

        logs={this.state.logs}
        getLogs={this.getLogs}
        clearLogs={this.clearLogs}

        stopStrategy={this.stopStrategy}
        startStrategy={this.startStrategy}
        strategyStatus1={this.state.strategyStatus1}
        strategyStatus2={this.state.strategyStatus2}
        strategyStatus3={this.state.strategyStatus3}
        strategyStatus4={this.state.strategyStatus4}
        strategyStatus5={this.state.strategyStatus5}
        fetchStrategyStatus={this.fetchStrategyStatus}
      />
    }

    return (
      <div className="App">
        {page}
        <ToastContainer />
      </div>
    );
  }
}

export default App;
