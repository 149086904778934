import { Component } from "react";

class Strategy4InfoBox extends Component {
  render() {
    return (
      <div className={this.props.show ? "modal is-active" : "modal"}>
        <div className="modal-background" onClick={this.props.handleClose}></div>

        <div className="modal-card">
          <header className="modal-card-head">
            <h5 className="modal-card-title">Condition Info (Strategy #4)</h5>
            <button className="delete" onClick={this.props.handleClose}></button>
          </header>

          <section className="modal-card-body">
            <p className="subtitle">Statistics info of condition - #{this.props.condition.id}</p>

            <h4 className="is-size-4 mb-3">Inputs:</h4>

            <table className="table is-striped is-hoverable is-fullwidth is-bordered">
              <tbody>
                <tr>
                  <th>Broker:</th>
                  <td>{this.props.condition.brokerName}</td>
                </tr>
                <tr>
                  <th>Datafeed:</th>
                  <td>{this.props.condition.datafeedName}</td>
                </tr>
                <tr>
                  <th>Scrip:</th>
                  <td>{this.props.condition.scrip}</td>
                </tr>
                <tr>
                  <th>Start Time:</th>
                  <td>{this.props.condition.startTime}</td>
                </tr>
                <tr>
                  <th>Strike Distance:</th>
                  <td>{this.props.condition.strikeDistance}</td>
                </tr>
                <tr>
                  <th>Expiry:</th>
                  <td>{this.props.condition.expiry}</td>
                </tr>
                <tr>
                  <th>Expiry Type:</th>
                  <td>{this.props.condition.expiryType}</td>
                </tr>
                <tr>
                  <th>Lots:</th>
                  <td>{this.props.condition.lots}</td>
                </tr>
                <tr>
                  <th>Timeframe:</th>
                  <td>{this.props.condition.timeframe}</td>
                </tr>
                <tr>
                  <th>Trading Mode:</th>
                  <td>{this.props.condition.tradingMode}</td>
                </tr>
                {/* <tr>
                  <th>Entry Buffer %:</th>
                  <td>{this.props.condition.entryBufferPct}</td>
                </tr>
                <tr>
                  <th>SL %:</th>
                  <td>{this.props.condition.slPct}</td>
                </tr>
                <tr>
                  <th>Target:</th>
                  <td>{this.props.condition.target}</td>
                </tr>
                <tr>
                  <th>Points to Trail At:</th>
                  <td>{this.props.condition.ptsToTrailAt}</td>
                </tr> */}
              </tbody>
            </table>

            <hr />

            <h4 className="is-size-4 mb-2">Statistics:</h4>

            <table className="table is-striped is-hoverable is-fullwidth is-bordered">
              <tbody>
                <tr>
                  <th>Stopped:</th>
                  <td>{this.props.condition.stopped ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <th>Next Move:</th>
                  <td>{this.props.condition.nextMove}</td>
                </tr>
                <tr>
                  <th>Entered Ins:</th>
                  <td>{this.props.condition.enteredIns}</td>
                </tr>
                <tr>
                  <th>Entered Qty:</th>
                  <td>{this.props.condition.enteredQty}</td>
                </tr>
                <tr>
                  <th>Entered Price:</th>
                  <td>{this.props.condition.enteredPrice}</td>
                </tr>
                <tr>
                  <th>LTP:</th>
                  <td>{this.props.condition.ltp}</td>
                </tr>
                <tr>
                  <th>Running P&L:</th>
                  <td>{this.props.condition.runningPnl}</td>
                </tr>
                <tr>
                  <th>Booked P&L:</th>
                  <td>{this.props.condition.bookedPnl}</td>
                </tr>
                <tr>
                  <th>Number of Trades:</th>
                  <td>{this.props.condition.numberOfTrades}</td>
                </tr>
              </tbody>
            </table>
          </section>

          <footer className="modal-card-foot"></footer>
        </div>
      </div>
    );
  }
}

export default Strategy4InfoBox;